import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { IoCloseOutline } from "react-icons/io5";
import { IoIosCheckmark } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import { useRagContext } from "../../../../contexts/RagContext";
import { GoPlus } from "react-icons/go";
import { ClickAwayListener } from "@mui/material";
import axios from "axios";
import { useUserContext } from "../../../../contexts/UserContext";
import { useHistory } from "react-router";

interface SelectFolderModalProps {}

const SelectFolderModal: React.FC<SelectFolderModalProps> = () => {
  const {
    folderList,
    conceptAddFolderVisible,
    conceptAddFolderSelection,
    closeConceptAddFolderModal,
    selectedFiles,
    selectedCreateConceptFiles,
    conceptFolders,
    getConceptFolders,
    closeCreateConceptDrawer,
    socketId,
    conceptAddType,
  } = useRagContext();

  const { user } = useUserContext();

  const [openAutocomplete, setOpenAutocomplete] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [createNew, setCreateNew] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");
  const [folders, setFolders] = useState<any>([]);
  const [selectedFolder, setSelectedFolder] = useState<any>(null);
  const history = useHistory();

  useEffect(() => {
    setFolders(conceptFolders);
  }, [conceptFolders]);

  const createFolder = async () => {
    try {
      let response = await axios.post(
        process.env.REACT_APP_API_URL + "api/createConceptFolder",
        {
          team_id: user?.organizationMemberships[0]?.organization.id,
          folder_name: newFolderName,
        }
      );
      if (response?.status === 200) {
        if (response?.data) {
          getConceptFolders();
          setSelectedFolder(response.data.result);
          setOpenAutocomplete(false);
          setNewFolderName("");
          setCreateNew(false);
        }
      }
      return response;
    } catch (error) {
      console.log(error);
      setOpenAutocomplete(false);
      setNewFolderName("");
      setCreateNew(false);
    }
  };

  const createConcept = async () => {
    if (selectedFolder) {
      let fileList = selectedFiles;
      let createConceptFileList = selectedCreateConceptFiles;

      let params: any = {
        team_id: user?.organizationMemberships[0]?.organization.id,
        concept_folder_id: selectedFolder.id,
        socketId: socketId,
      };
      if (conceptAddType === "selected_source") {
        params.file_list = fileList;
        if (fileList.length > 0) {
          params.pinecone_id = fileList[0].split("/")[2];
        } else if (folderList.length > 0) {
          params.pinecone_id = folderList[0].id;
        } else {
          params.pinecone_id = "";
        }
      } else if (conceptAddType === "window_selection") {
        params.context = conceptAddFolderSelection;
        params.pinecone_id = "";
      } else if (conceptAddType === "create_concept_drawer") {
        params.file_list = createConceptFileList;
        if (createConceptFileList.length > 0) {
          params.pinecone_id = createConceptFileList[0]?.split("/")[2];
        } else if (folderList.length > 0) {
          params.pinecone_id = folderList[0].id;
        } else {
          params.pinecone_id = "";
        }
        closeCreateConceptDrawer();
      }

      try {
        let response = await axios.post(
          process.env.REACT_APP_API_URL + "api/createConcept",
          params
        );
        if (response?.status === 200) {
          const searchParams = new URLSearchParams();
          searchParams.set("render_type", "modal");
          searchParams.set("render_modal", "concept_modal");
          searchParams.set("concept_id", response.data.concept_id);

          history.push({
            pathname: "/concept", // you can change the path here
            search: `?${searchParams.toString()}`, // append your query parameters here
          });

          closeConceptAddFolderModal();
          getConceptFolders();
        }
        return response;
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <Modal
      open={conceptAddFolderVisible}
      onClose={closeConceptAddFolderModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 w-[327px] xl:w-[436px] bg-white rounded-[10px] shadow-lg">
        <div className="py-3 xl:py-4 px-[18px] xl:px-6 flex items-center justify-between border-b border-solid border-[#E4E4E7] gap-3 xl:gap-4">
          <div className="">
            <div className="text-[13px] xl:text-lg leading-[16px] xl:leading-[22px] font-bold text-[#18181B] mb-[3px] xl:mb-1">
              Add Folder
            </div>
            <div className="text-[10px] xl:text-sm leading-[16px] xl:leading-[22px] font-medium text-[#71717A]">
              To organize your concept effectively select an existing folder or
              create a new one.
            </div>
          </div>
          <button
            className="w-[30px] xl:w-10 h-[30px] xl:h-10 border border-solid border-[#D4D4D8] flex items-center justify-center flex-none rounded-full"
            onClick={() => closeConceptAddFolderModal()}
          >
            <IoCloseOutline className="w-[18px] xl:w-6 h-[18px] xl:h-6 text-[#09090B]" />
          </button>
        </div>
        <div className="p-[18px] xl:p-6">
          <div className="w-full mb-6 xl:mb-8">
            <div className="px-[3px] xl:px-1 mb-[3px] xl:mb-1 text-xs xl:text-base font-normal text-[#09090B]">
              Select Folder
            </div>
            <div className="w-full relative">
              <div
                className={`flex justify-between items-center border border-solid border-[#D4D4D8] px-3 xl:px-4 py-[10px] xl:py-[14px] cursor-pointer ${
                  openAutocomplete ? "rounded-t-lg" : "rounded-lg"
                }`}
                onClick={() => setOpenAutocomplete(true)}
              >
                <span className="text-[10px] xl:text-sm font-medium text-[#71717A]">
                  {selectedFolder ? selectedFolder.folder_name : "Select"}
                </span>
                <IoIosArrowDown className="w-[15px] xl:w-5 h-[15px] xl:h-5 text-[#18181B] rounded-b-lg" />
              </div>
              {openAutocomplete && (
                <ClickAwayListener
                  onClickAway={() => {
                    setOpenAutocomplete(false);
                    setSearchText("");
                  }}
                >
                  <div
                    className={`absolute bottom-0 left-0 w-full bg-white translate-y-full rounded-b-lg border-l border-r border-b border-solid border-[#EAECF0]`}
                  >
                    <div className="p-1 xl:p-[6px] mb-[3px] xl:mb-1">
                      <input
                        type="text"
                        className="w-full outline-none px-[9px] xl:px-3 py-1 xl:py-[6px] text-xs xl:text-base font-medium text-[#3F3F46] border-b border-dashed border-[#D4D4D8]"
                        placeholder="Search"
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                      />
                    </div>
                    <div className="flex flex-col max-h-[165px] xl:max-h-[220px] overflow-auto p-1 xl:p-[6px] gap-[3px] xl:gap-1 mb-[3px] xl:mb-1">
                      {folders
                        .filter((e: any) =>
                          e.folder_name
                            .toLowerCase()
                            .includes(searchText.toLowerCase())
                        )
                        .map((e: any, key: number) => (
                          <div
                            className="px-[9px] xl:px-3 py-[7px] xl:py-[10px] cursor-pointer"
                            key={key}
                            onClick={() => {
                              setSelectedFolder(e);
                              setOpenAutocomplete(false);
                            }}
                          >
                            <span className="text-xs xl:text-base font-medium text-[#3F3F46]">
                              {e.folder_name}
                            </span>
                          </div>
                        ))}
                    </div>
                    <div className="border-t border-dashed border-[#D4D4D8] w-full mb-[3px] xl:mb-1"></div>
                    {createNew ? (
                      <div className="p-1 xl:p-[6px]">
                        <div className="relative flex items-center gap-[6px] xl:gap-2 border border-solid border-[#D4D4D8] px-3 xl:px-4 py-[7px] xl:py-[10px] h-[33px] xl:h-11 rounded-lg">
                          <input
                            type="text"
                            className="outline-none border-none bg-transparent text-[10px] xl:text-sm font-medium text-[#71717A] flex-1"
                            value={newFolderName}
                            onChange={(e) => setNewFolderName(e.target.value)}
                          />
                          <button
                            className="outline-none border-none w-[15px] xl:w-5 h-[15px] xl:h-5"
                            onClick={() => {
                              setCreateNew(false);
                              setNewFolderName("");
                            }}
                          >
                            <IoCloseOutline className="w-[15px] xl:w-5 h-[15px] xl:h-5 text-[#52525B]" />
                          </button>
                          <button
                            className="outline-none border-none w-[15px] xl:w-5 h-[15px] xl:h-5"
                            onClick={() => {
                              createFolder();
                            }}
                          >
                            <IoIosCheckmark className="w-[15px] xl:w-5 h-[15px] xl:h-5 text-[#52525B]" />
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div
                        className="px-[13px] xl:px-[18px] py-[9px] xl:py-3 flex items-center gap-[6px] xl:gap-2 cursor-pointer"
                        onClick={() => setCreateNew(true)}
                      >
                        <GoPlus className="w-[18px] xl:w-6 h-[18px] xl:h-6 text-[#3B82F6]" />
                        <span className="text-xs xl:text-base font-medium text-[#3B82F6]">
                          Create New
                        </span>
                      </div>
                    )}
                  </div>
                </ClickAwayListener>
              )}
            </div>
          </div>
          <div className="flex gap-3 xl:gap-4">
            <button
              className="outline-none border border-solid border-[#71717A] h-9 xl:h-12 flex items-center justify-center rounded-lg w-full"
              onClick={() => closeConceptAddFolderModal()}
            >
              <span className="text-xs xl:text-base font-bold text-[#71717A]">
                Cancel
              </span>
            </button>
            <button
              className="outline-none bg-[#3B82F6] h-9 xl:h-12 flex items-center justify-center rounded-lg w-full"
              onClick={() => createConcept()}
            >
              <span className="text-xs xl:text-base font-bold text-white">
                Save
              </span>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SelectFolderModal;
