import React, { useEffect, useState } from "react";
import { HiOutlineFolderMinus, HiOutlineFolderPlus } from "react-icons/hi2";
import { useRagContext } from "../../../contexts/RagContext";
import BpCheckbox from "../../common/BPCheckbox";
import $ from "jquery";

interface BrainstormFolderComponentProps {
  folder: any;
}

interface TreeNode {
  id: string;
  name: string;
  children?: TreeNode[];
  path: string;
  secured_path: string;
  isExpanded?: boolean;
  isFolder?: boolean;
  isEdit?: boolean;
  isCreate?: boolean;
  isRequireOCR: boolean;
  folder_id: string;
  isOCRProcessing: boolean;
  file_path: string;
}

const BrainstormFolder: React.FC<BrainstormFolderComponentProps> = ({
  folder,
}) => {
  const [tree, setTree] = useState<TreeNode[]>([]);
  const {
    toggleSelectBrainstormFolder,
    toggleSelectBrainstormFile,
    isSelectedBrainstormFolder,
    isSelectedBrainstormFile,
  } = useRagContext();

  useEffect(() => {
    if (folder?.structure) {
      setTree(buildTree(folder?.structure));
    }
  }, [folder?.structure]);

  const toggleNode = (e: any, node: TreeNode) => {
    node.isExpanded = !node.isExpanded;
    setTree([...tree]);
    const slashCount = (node.path.match(/\//g) || []).length || 1;
    let folderContainer: any = $("#start-brainstorm-folder-container");
    if (folderContainer) {
      folderContainer.animate({ scrollLeft: 50 * slashCount }, 500);
    }
  };

  const buildTree = (
    data: any,
    path: string = "",
    secured_path: string = ""
  ): TreeNode[] => {
    return Object.keys(data).map((key) => ({
      folder_id: folder.id,
      id: data[key].id,
      name: key,
      children: buildTree(
        data[key].children || {},
        path + "/" + key,
        secured_path + "/" + data[key].id
      ),
      path: path + "/" + key,
      secured_path: secured_path,
      isExpanded: false,
      isFolder: data[key]?.isFolder ? data[key]?.isFolder : false,
      isEdit: false,
      isCreate: data[key]?.isCreate,
      isRequireOCR: data[key]?.isRequireOCR ? data[key]?.isRequireOCR : false,
      isOCRProcessing: false,
      file_path: data[key]?.file_path,
    }));
  };

  const renderTree = (nodes: TreeNode[], folderId?: string) => (
    <>
      {nodes.map((node, index) => (
        <div key={index}>
          {node.isFolder === true ? (
            <>
              <div
                key={index}
                className={`px-[6px] xl:px-3 py-[7px] xl:py-[10px] flex items-start gap-[6px] xl:gap-3 rounded-[10px] group sidebar-folder w-[300px] xl:w-[400px]`}
              >
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    toggleNode(e, node);
                  }}
                  className="outline-none flex-none"
                >
                  {node.isExpanded ? (
                    <HiOutlineFolderMinus className="text-lg xl:text-2xl text-[#3B82F6] flex-none" />
                  ) : (
                    <HiOutlineFolderPlus className="text-lg xl:text-2xl text-[#3B82F6] flex-none" />
                  )}
                </button>
                <p
                  className="text-xs xl:text-base font-medium text-[#3B82F6] folder-name text-break min-w-[97px] xl:min-w-[130px]"
                  onClick={(e) => {
                    toggleNode(e, node);
                  }}
                >
                  {node.name}
                </p>
                <button
                  className={`outline-none flex-none ml-auto flex h-[18px] xl:h-6 items-center ${
                    isSelectedBrainstormFolder(node.id)
                      ? "visible"
                      : "invisible group-hover:visible"
                  }`}
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleSelectBrainstormFolder(node.id, node.folder_id);
                  }}
                >
                  <BpCheckbox
                    size="small"
                    className="w-[10px] xl:w-[14px] h-[10px] xl:h-[14px]"
                    checked={isSelectedBrainstormFolder(node.id)}
                  ></BpCheckbox>
                </button>
              </div>
              {node.isExpanded && (
                <div
                  className={`relative pl-[27px] xl:pl-9 flex-col sidebar-folder-items ${
                    node.isExpanded ? "flex" : "hidden"
                  }`}
                >
                  {node.children && node.children.length > 0 ? (
                    renderTree(node.children, node.id)
                  ) : (
                    <></>
                  )}
                </div>
              )}
            </>
          ) : (
            <>
              <div
                className={`w-[300px] xl:w-[400px] sidebar-folder-item px-[6px] xl:px-3 py-[7px] xl:py-[10px] rounded-xl flex items-start gap-1 justify-between cursor-pointer group bg-transparent text-[#52525B]`}
              >
                <p className={`text-xs xl:text-base document-title`}>
                  {node.name}
                </p>
                <div className="flex gap-[6px] xl:gap-2 ml-auto">
                  <button
                    className={`outline-none flex-none ml-auto flex h-[18px] xl:h-6 items-center ${
                      isSelectedBrainstormFile(node.file_path)
                        ? "visible"
                        : "invisible group-hover:visible"
                    }`}
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleSelectBrainstormFile(
                        node.file_path,
                        node.folder_id
                      );
                    }}
                  >
                    <BpCheckbox
                      size="small"
                      className="w-[10px] xl:w-[14px] h-[10px] xl:h-[14px]"
                      checked={isSelectedBrainstormFile(node.file_path)}
                    ></BpCheckbox>
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      ))}
    </>
  );
  return <>{renderTree(tree)}</>;
};

export default BrainstormFolder;
