import React, { createContext, useContext, useMemo, useState } from "react";
import axios from "axios";
import { useUserContext } from "./UserContext";
import { useLocation } from "react-router";

interface RagContextType {
  folderList: any[];
  selectedFiles: any;
  selectedBrainstormFiles: any;
  selectedCreateConceptFiles: any;
  conceptModalVisible: boolean | undefined;
  selectedConceptId: string | undefined | null;
  selectedBrainstormId: string | undefined | null;
  fileManagerModalVisible: boolean;
  conceptManagerModalVisible: boolean;
  setting: any;
  conceptAddFolderVisible: boolean;
  conceptAddFolderSelection: string;
  conceptFolders: any[];
  brainstormLists: any[];
  socket: any;
  socketId: any;
  chatTopicId: any;
  pageName: any;
  retriever: any;
  subRetriever: any;
  selectedUploadFiles: any;
  activityLogs: any;
  activityLogsModalVisible: any;
  shareFeedbackVisible: boolean;
  openSettingsModal: boolean;
  conceptAddType: string;
  brainStormModalVisible: boolean | undefined;
  startBrainstormDrawerVisible: boolean | undefined;
  createConceptDrawerVisible: boolean | undefined;
  openedDocument: string;
  selectedFolderID: string;
  selectedSubFolderID: string;
  pageNumber: number;
  documentUpdated: boolean;
  setOpenedDocument: (arg: any) => void;
  setSelectedFolderID: (arg: any) => void;
  setSelectedSubFolderID: (arg: any) => void;
  setPageNumber: (arg: any) => void;
  setDocumentUpdated: (arg: any) => void;
  setChatTopicId: (arg: any) => void;
  setRetriever: (arg: any) => void;
  setSubRetriever: (arg: any) => void;
  setFolderList: (arg: any) => void;
  setSelectedFiles: (arg: any) => void;
  toggleSelectFolder: (arg: any, topFolderId: string) => void;
  toggleSelectFile: (filePath: string, topFolderId: string) => void;
  isSelectedFolder: (arg: any) => boolean;
  isSelectedFile: (filePath: string) => boolean;
  setSelectedBrainstormFiles: (arg: any) => void;
  toggleSelectBrainstormFolder: (arg: any, topFolderId: string) => void;
  toggleSelectBrainstormFile: (filePath: string, topFolderId: string) => void;
  isSelectedBrainstormFolder: (arg: any) => boolean;
  isSelectedBrainstormFile: (filePath: any) => boolean;
  setSelectedCreateConceptFiles: (arg: any) => void;
  toggleSelectCreateConceptFolder: (arg: any, topFolderId: string) => void;
  toggleSelectCreateConceptFile: (
    filePath: string,
    topFolderId: string
  ) => void;
  isSelectedCreateConceptFolder: (arg: any) => boolean;
  isSelectedCreateConceptFile: (filePath: any) => boolean;
  openFileManagerModal: () => void;
  closeFileManagerModal: () => void;
  openConceptManagerModal: () => void;
  closeConceptManagerModal: () => void;
  getSettingData: () => void;
  openConceptAddFolderModal: (type: string, selection?: string) => void;
  closeConceptAddFolderModal: () => void;
  getConceptFolders: () => void;
  getBrainstormList: () => void;
  setSocketInterface: (socket: any, socketId: any) => void;
  fetchFolders: () => void;
  setSelectedUploadFiles: (arg: any) => void;
  openActivityLogsModal: (log: any) => void;
  closeActivityLogsModal: () => void;
  openShareFeedbackModal: (arg: any) => void;
  closeShareFeedbackModal: () => void;
  handleSettingModalOpen: () => void;
  handleCloseSettingsModal: () => void;
  openStartBrainstormDrawer: () => void;
  closeStartBrainstormDrawer: () => void;
  openCreateConceptDrawer: () => void;
  closeCreateConceptDrawer: () => void;
}

const RagContext = createContext<RagContextType | undefined>(undefined);

export const RagContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const location = useLocation();

  // Get search params from the URL

  const [originFolderList, setFolderList] = useState<any[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<any>([]);
  const [selectedBrainstormFiles, setSelectedBrainstormFiles] = useState<any>(
    []
  );
  const [selectedCreateConceptFiles, setSelectedCreateConceptFiles] =
    useState<any>([]);
  const [lastSelectedTopFolderId, setLastSelectedTopFolderId] =
    useState<any>("");
  const [
    lastSelectedBrainstormTopFolderId,
    setLastSelectedBrainstormTopFolderId,
  ] = useState<any>("");
  const [
    lastSelectedCreateConceptTopFolderId,
    setLastSelectedCreateConceptTopFolderId,
  ] = useState<any>("");
  const [socket, setSocket] = useState(null);
  const [pageName, setPageName] = useState<string>("Chat");
  const [socketId, setSocketId] = useState("");
  const [chatTopicId, setChatTopicId] = useState("");
  const [retriever, setRetriever] = useState<
    "selected_source" | "tavily" | "google_patents" | "serpapi"
  >("selected_source");
  const [subRetriever, setSubRetriever] = useState<
    "google_scholar" | "arxiv" | "sciencedirect" | ""
  >("");
  const conceptModalVisible = useMemo(() => {
    if (location) {
      const searchParams = new URLSearchParams(location?.search);
      if (
        searchParams.get("render_type") === "modal" &&
        searchParams.get("render_modal") === "concept_modal" &&
        searchParams.get("concept_id")
      ) {
        return true;
      } else {
        return false;
      }
    }
  }, [location]);
  const brainStormModalVisible = useMemo(() => {
    if (location) {
      const searchParams = new URLSearchParams(location?.search);
      if (
        searchParams.get("render_type") === "modal" &&
        searchParams.get("render_modal") === "brainstorm_modal" &&
        searchParams.get("brainstorm_id")
      ) {
        return true;
      } else {
        return false;
      }
    }
  }, [location]);

  const selectedConceptId = useMemo(() => {
    if (location) {
      const searchParams = new URLSearchParams(location?.search);
      if (
        searchParams.get("render_type") === "modal" &&
        searchParams.get("render_modal") === "concept_modal" &&
        searchParams.get("concept_id")
      ) {
        return searchParams.get("concept_id");
      } else {
        return "";
      }
    }
  }, [location]);

  const selectedBrainstormId = useMemo(() => {
    if (location) {
      const searchParams = new URLSearchParams(location?.search);
      if (
        searchParams.get("render_type") === "modal" &&
        searchParams.get("render_modal") === "brainstorm_modal" &&
        searchParams.get("brainstorm_id")
      ) {
        return searchParams.get("brainstorm_id");
      } else {
        return "";
      }
    }
  }, [location]);

  const [setting, setSetting] = useState<any>({});
  const { user } = useUserContext();
  const [fileManagerModalVisible, setFileManagerModalVisible] =
    useState<any>(false);
  const [conceptManagerModalVisible, setConceptManagerModalVisible] =
    useState<any>(false);
  const [conceptAddFolderVisible, setConceptAddFolderVisible] =
    useState<any>(false);
  const [conceptAddFolderSelection, setConceptAddFolderSelection] =
    useState<any>("");
  const [conceptFolders, setConceptFolders] = useState<any[]>([]);
  const [brainstormLists, setBrainstormLists] = useState<any[]>([]);
  const [selectedUploadFiles, setSelectedUploadFiles] = useState<any>([]);

  const [activityLogsModalVisible, setActivityLogsModalVisible] =
    useState(false);
  const [activityLogs, setActivityLogs] = useState<any>([]);
  const [shareFeedbackVisible, setShareFeedbackVisible] = useState<any>(false);
  const [openSettingsModal, setOpenSettingsModal] = useState(false);
  const [conceptAddType, setConceptAddType] = useState<string>("");
  const [startBrainstormDrawerVisible, setStartBrainstormDrawerVisible] =
    useState<any>(false);
  const [openedDocument, setOpenedDocument] = useState("");
  const [selectedFolderID, setSelectedFolderID] = useState("");
  const [selectedSubFolderID, setSelectedSubFolderID] = useState("");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [documentUpdated, setDocumentUpdated] = useState(false);

  const [createConceptDrawerVisible, setCreateConceptDrawerVisible] =
    useState<any>(false);

  const folderList: any[] = useMemo(() => {
    const refactFolderList = (parentFolder: any, file_path = "") => {
      let result: any = {};
      Object.keys(parentFolder).forEach((key) => {
        result[key] = {
          ...parentFolder[key],
          children: refactFolderList(
            parentFolder[key].children,
            `${file_path}/${parentFolder[key].id}`
          ),
        };
        if (!parentFolder[key].isFolder) {
          result[key].file_path = `${file_path}/${key}`;
        }
      });
      return result;
    };

    if (originFolderList) {
      let tempFolderList = JSON.parse(JSON.stringify(originFolderList));

      tempFolderList = tempFolderList.map((parentFolder: any) => {
        return {
          ...parentFolder,
          structure: refactFolderList(
            parentFolder.structure,
            `upload/${user?.organizationMemberships[0]?.organization.id}/${parentFolder.id}`
          ),
        };
      });
      return tempFolderList;
    } else {
      return [];
    }
  }, [originFolderList, user]);

  const handleSettingModalOpen = () => {
    setOpenSettingsModal(true);
  };

  const handleCloseSettingsModal = () => {
    setOpenSettingsModal(false);
  };
  const setSocketInterface = (socket: any, socketId: any) => {
    setSocket(socket);
    setSocketId(socketId);
  };

  const toggleSelectFolder = (folder_id: any, topFolderId: string) => {
    let tempSelectedFiles: any;
    if (lastSelectedTopFolderId !== topFolderId) {
      tempSelectedFiles = [];
    } else {
      tempSelectedFiles = JSON.parse(JSON.stringify(selectedFiles));
    }

    folderList.forEach((parentFolder) => {
      toggleSelectSubFolder(
        parentFolder?.structure[Object.keys(parentFolder?.structure)[0]],
        folder_id,
        isSelectedFolder(folder_id),
        tempSelectedFiles
      );
    });
    setLastSelectedTopFolderId(topFolderId);
    setSelectedFiles(tempSelectedFiles);
  };

  const toggleSelectSubFolder = (
    folder: any,
    folder_id: any,
    selected: boolean,
    selectedFiles: any
  ) => {
    if (folder.id === folder_id) {
      Object.keys(folder.children).forEach((key) => {
        let item = folder.children[key];
        if (item.isFolder) {
          toggleSelectSubFolder(item, item.id, selected, selectedFiles);
        } else {
          let index = selectedFiles.findIndex((e: any) => e === item.file_path);
          if (selected) {
            selectedFiles.splice(index, 1);
          } else {
            if (index === -1) selectedFiles.push(item.file_path);
          }
        }
      });
    } else {
      Object.keys(folder.children).forEach((key) => {
        let item = folder.children[key];
        if (item.isFolder) {
          toggleSelectSubFolder(item, folder_id, selected, selectedFiles);
        }
      });
    }
  };

  const toggleSelectFile = (filePath: string, topFolderId: string) => {
    let tempSelectedFiles: any;
    if (lastSelectedTopFolderId !== topFolderId) {
      tempSelectedFiles = [];
    } else {
      tempSelectedFiles = JSON.parse(JSON.stringify(selectedFiles));
    }
    let index = tempSelectedFiles.findIndex((e: any) => e === filePath);
    if (index !== -1) {
      tempSelectedFiles.splice(index, 1);
    } else {
      tempSelectedFiles.push(filePath);
    }
    setLastSelectedTopFolderId(topFolderId);
    setSelectedFiles(tempSelectedFiles);
  };

  const isSelectedFolder = (folder_id: any): boolean => {
    let isSelected = false;
    folderList.forEach((parentFolder) => {
      if (
        isSelectedSubFolder(
          parentFolder?.structure[Object.keys(parentFolder?.structure)[0]],
          folder_id
        )
      ) {
        isSelected = true;
      }
    });

    return isSelected;
  };

  const isSelectedSubFolder = (folder: any, folder_id: any): boolean => {
    if (folder.id === folder_id) {
      let isSelected = true;
      if (Object.keys(folder.children).length === 0) return false;
      Object.keys(folder.children).forEach((key) => {
        let item = folder.children[key];
        if (
          !(
            (item.isFolder && isSelectedSubFolder(item, item.id)) ||
            (!item.isFolder && isSelectedFile(item.file_path))
          )
        ) {
          isSelected = false;
        }
      });
      return isSelected;
    } else {
      let isSelected = false;
      if (Object.keys(folder.children).length === 0) return false;
      Object.keys(folder.children).forEach((key) => {
        let item = folder.children[key];
        if (item.isFolder && isSelectedSubFolder(item, folder_id)) {
          isSelected = true;
        }
      });
      return isSelected;
    }
  };

  const isSelectedFile = (filePath: string): boolean => {
    if (selectedFiles.find((e: any) => e === filePath)) {
      return true;
    } else {
      return false;
    }
  };

  const getBrainstormList = async () => {
    try {
      let response = await axios.post(
        process.env.REACT_APP_API_URL + "api/getBrainStormList",
        {
          team_id: user?.organizationMemberships[0]?.organization.id,
        }
      );
      if (response?.status === 200) {
        if (response?.data) {
          setBrainstormLists(response.data.data);
        } else {
          setBrainstormLists([]);
        }
      }
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const toggleSelectBrainstormFolder = (
    folder_id: any,
    topBrainstormFolderId: string
  ) => {
    let tempSelectedBrainstormFiles: any;
    if (lastSelectedBrainstormTopFolderId !== topBrainstormFolderId) {
      tempSelectedBrainstormFiles = [];
    } else {
      tempSelectedBrainstormFiles = JSON.parse(
        JSON.stringify(selectedBrainstormFiles)
      );
    }

    folderList.forEach((parentFolder) => {
      toggleSelectBrainstormSubFolder(
        parentFolder?.structure[Object.keys(parentFolder?.structure)[0]],
        folder_id,
        isSelectedBrainstormFolder(folder_id),
        tempSelectedBrainstormFiles
      );
    });
    setLastSelectedBrainstormTopFolderId(topBrainstormFolderId);
    setSelectedBrainstormFiles(tempSelectedBrainstormFiles);
  };

  const toggleSelectBrainstormSubFolder = (
    folder: any,
    folder_id: any,
    selected: boolean,
    selectedBrainstormFiles: any
  ) => {
    if (folder.id === folder_id) {
      Object.keys(folder.children).forEach((key) => {
        let item = folder.children[key];
        if (item.isFolder) {
          toggleSelectBrainstormSubFolder(
            item,
            item.id,
            selected,
            selectedBrainstormFiles
          );
        } else {
          let index = selectedBrainstormFiles.findIndex(
            (e: any) => e === item.file_path
          );
          if (selected) {
            selectedBrainstormFiles.splice(index, 1);
          } else {
            if (index === -1) selectedBrainstormFiles.push(item.file_path);
          }
        }
      });
    } else {
      Object.keys(folder.children).forEach((key) => {
        let item = folder.children[key];
        if (item.isFolder) {
          toggleSelectBrainstormSubFolder(
            item,
            folder_id,
            selected,
            selectedBrainstormFiles
          );
        }
      });
    }
  };

  const toggleSelectBrainstormFile = (
    filePath: string,
    topBrainstormFolderId: string
  ) => {
    let tempSelectedBrainstormFiles: any;
    if (lastSelectedBrainstormTopFolderId !== topBrainstormFolderId) {
      tempSelectedBrainstormFiles = [];
    } else {
      tempSelectedBrainstormFiles = JSON.parse(
        JSON.stringify(selectedBrainstormFiles)
      );
    }
    let index = tempSelectedBrainstormFiles.findIndex(
      (e: any) => e === filePath
    );
    if (index !== -1) {
      tempSelectedBrainstormFiles.splice(index, 1);
    } else {
      tempSelectedBrainstormFiles.push(filePath);
    }
    setLastSelectedBrainstormTopFolderId(topBrainstormFolderId);
    setSelectedBrainstormFiles(tempSelectedBrainstormFiles);
  };

  const isSelectedBrainstormFolder = (folder_id: any): boolean => {
    let isSelected = false;
    folderList.forEach((parentFolder) => {
      if (
        isSelectedBrainstormSubFolder(
          parentFolder?.structure[Object.keys(parentFolder?.structure)[0]],
          folder_id
        )
      ) {
        isSelected = true;
      }
    });

    return isSelected;
  };

  const isSelectedBrainstormSubFolder = (
    folder: any,
    folder_id: any
  ): boolean => {
    if (folder.id === folder_id) {
      let isSelected = true;
      if (Object.keys(folder.children).length === 0) return false;
      Object.keys(folder.children).forEach((key) => {
        let item = folder.children[key];
        if (
          !(
            (item.isFolder && isSelectedBrainstormSubFolder(item, item.id)) ||
            (!item.isFolder && isSelectedBrainstormFile(item.file_path))
          )
        ) {
          isSelected = false;
        }
      });
      return isSelected;
    } else {
      let isSelected = false;
      if (Object.keys(folder.children).length === 0) return false;
      Object.keys(folder.children).forEach((key) => {
        let item = folder.children[key];
        if (item.isFolder && isSelectedBrainstormSubFolder(item, folder_id)) {
          isSelected = true;
        }
      });
      return isSelected;
    }
  };

  const isSelectedBrainstormFile = (filePath: string): boolean => {
    if (selectedBrainstormFiles.find((e: any) => e === filePath)) {
      return true;
    } else {
      return false;
    }
  };

  const toggleSelectCreateConceptFolder = (
    folder_id: any,
    topCreateConceptFolderId: string
  ) => {
    let tempSelectedCreateConceptFiles: any;
    if (lastSelectedCreateConceptTopFolderId !== topCreateConceptFolderId) {
      tempSelectedCreateConceptFiles = [];
    } else {
      tempSelectedCreateConceptFiles = JSON.parse(
        JSON.stringify(selectedCreateConceptFiles)
      );
    }

    folderList.forEach((parentFolder) => {
      toggleSelectCreateConceptSubFolder(
        parentFolder?.structure[Object.keys(parentFolder?.structure)[0]],
        folder_id,
        isSelectedCreateConceptFolder(folder_id),
        tempSelectedCreateConceptFiles
      );
    });
    setLastSelectedCreateConceptTopFolderId(topCreateConceptFolderId);
    setSelectedCreateConceptFiles(tempSelectedCreateConceptFiles);
  };

  const toggleSelectCreateConceptSubFolder = (
    folder: any,
    folder_id: any,
    selected: boolean,
    selectedCreateConceptFiles: any
  ) => {
    if (folder.id === folder_id) {
      Object.keys(folder.children).forEach((key) => {
        let item = folder.children[key];
        if (item.isFolder) {
          toggleSelectCreateConceptSubFolder(
            item,
            item.id,
            selected,
            selectedCreateConceptFiles
          );
        } else {
          let index = selectedCreateConceptFiles.findIndex(
            (e: any) => e === item.file_path
          );
          if (selected) {
            selectedCreateConceptFiles.splice(index, 1);
          } else {
            if (index === -1) selectedCreateConceptFiles.push(item.file_path);
          }
        }
      });
    } else {
      Object.keys(folder.children).forEach((key) => {
        let item = folder.children[key];
        if (item.isFolder) {
          toggleSelectCreateConceptSubFolder(
            item,
            folder_id,
            selected,
            selectedCreateConceptFiles
          );
        }
      });
    }
  };

  const toggleSelectCreateConceptFile = (
    filePath: string,
    topCreateConceptFolderId: string
  ) => {
    let tempSelectedCreateConceptFiles: any;
    if (lastSelectedCreateConceptTopFolderId !== topCreateConceptFolderId) {
      tempSelectedCreateConceptFiles = [];
    } else {
      tempSelectedCreateConceptFiles = JSON.parse(
        JSON.stringify(selectedCreateConceptFiles)
      );
    }
    let index = tempSelectedCreateConceptFiles.findIndex(
      (e: any) => e === filePath
    );
    if (index !== -1) {
      tempSelectedCreateConceptFiles.splice(index, 1);
    } else {
      tempSelectedCreateConceptFiles.push(filePath);
    }
    setLastSelectedCreateConceptTopFolderId(topCreateConceptFolderId);
    setSelectedCreateConceptFiles(tempSelectedCreateConceptFiles);
  };

  const isSelectedCreateConceptFolder = (folder_id: any): boolean => {
    let isSelected = false;
    folderList.forEach((parentFolder) => {
      if (
        isSelectedCreateConceptSubFolder(
          parentFolder?.structure[Object.keys(parentFolder?.structure)[0]],
          folder_id
        )
      ) {
        isSelected = true;
      }
    });

    return isSelected;
  };

  const isSelectedCreateConceptSubFolder = (
    folder: any,
    folder_id: any
  ): boolean => {
    if (folder.id === folder_id) {
      let isSelected = true;
      if (Object.keys(folder.children).length === 0) return false;
      Object.keys(folder.children).forEach((key) => {
        let item = folder.children[key];
        if (
          !(
            (item.isFolder &&
              isSelectedCreateConceptSubFolder(item, item.id)) ||
            (!item.isFolder && isSelectedCreateConceptFile(item.file_path))
          )
        ) {
          isSelected = false;
        }
      });
      return isSelected;
    } else {
      let isSelected = false;
      if (Object.keys(folder.children).length === 0) return false;
      Object.keys(folder.children).forEach((key) => {
        let item = folder.children[key];
        if (
          item.isFolder &&
          isSelectedCreateConceptSubFolder(item, folder_id)
        ) {
          isSelected = true;
        }
      });
      return isSelected;
    }
  };

  const isSelectedCreateConceptFile = (filePath: string): boolean => {
    if (selectedCreateConceptFiles.find((e: any) => e === filePath)) {
      return true;
    } else {
      return false;
    }
  };

  const getSettingData = async () => {
    try {
      const result = await axios.post(
        process.env.REACT_APP_API_URL + "api/getSetting",
        {
          team_id: user?.organizationMemberships[0]?.organization.id,
        }
      );
      setSetting(result.data.data);
    } catch (error) {}
  };

  const openFileManagerModal = () => {
    setFileManagerModalVisible(true);
  };

  const closeFileManagerModal = () => {
    setFileManagerModalVisible(false);
  };

  const openConceptManagerModal = () => {
    setConceptManagerModalVisible(true);
  };

  const closeConceptManagerModal = () => {
    setConceptManagerModalVisible(false);
  };

  const openConceptAddFolderModal = (type: string, selection?: string) => {
    setConceptAddFolderVisible(true);
    setConceptAddType(type);
    if (type === "selected_source") {
      setConceptAddFolderSelection("");
    } else if (type === "create_concept_drawer") {
      setConceptAddFolderSelection("");
    } else if (type === "window_selection") {
      setConceptAddFolderSelection(selection);
    }
  };

  const closeConceptAddFolderModal = () => {
    setConceptAddFolderVisible(false);
    setConceptAddFolderSelection("");
  };

  const openStartBrainstormDrawer = () => {
    setStartBrainstormDrawerVisible(true);
  };

  const closeStartBrainstormDrawer = () => {
    setStartBrainstormDrawerVisible(false);
  };

  const openCreateConceptDrawer = () => {
    setCreateConceptDrawerVisible(true);
  };

  const closeCreateConceptDrawer = () => {
    setCreateConceptDrawerVisible(false);
  };

  const getConceptFolders = async () => {
    try {
      let response = await axios.post(
        process.env.REACT_APP_API_URL + "api/getConceptFolders",
        {
          team_id: user?.organizationMemberships[0]?.organization.id,
        }
      );
      if (response?.status === 200) {
        if (response?.data) {
          setConceptFolders(response.data.data);
        } else {
          setConceptFolders([]);
        }
      }
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const fetchFolders = async () => {
    try {
      const result = await axios.post(
        process.env.REACT_APP_API_URL + "api/getFolders",
        {
          team_id: user?.organizationMemberships[0]?.organization.id,
        }
      );
      setFolderList(result.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const openActivityLogsModal = (logs: any) => {
    setActivityLogs(logs);
    setActivityLogsModalVisible(true);
  };
  const closeActivityLogsModal = () => {
    setActivityLogsModalVisible(false);
  };

  const openShareFeedbackModal = (page: string) => {
    setPageName(page);
    setShareFeedbackVisible(true);
  };
  const closeShareFeedbackModal = () => {
    setShareFeedbackVisible(false);
  };

  const contextValue = {
    folderList,
    selectedFiles,
    selectedBrainstormFiles,
    selectedCreateConceptFiles,
    conceptModalVisible,
    brainStormModalVisible,
    startBrainstormDrawerVisible,
    createConceptDrawerVisible,
    selectedConceptId,
    selectedBrainstormId,
    fileManagerModalVisible,
    conceptManagerModalVisible,
    setting,
    conceptAddFolderVisible,
    conceptAddFolderSelection,
    conceptFolders,
    brainstormLists,
    socket,
    socketId,
    chatTopicId,
    pageName,
    retriever,
    subRetriever,
    selectedUploadFiles,
    activityLogs,
    activityLogsModalVisible,
    shareFeedbackVisible,
    openSettingsModal,
    conceptAddType,
    openedDocument,
    selectedFolderID,
    selectedSubFolderID,
    pageNumber,
    documentUpdated,
    setOpenedDocument,
    setSelectedFolderID,
    setSelectedSubFolderID,
    setPageNumber,
    setDocumentUpdated,
    setChatTopicId,
    setRetriever,
    setSubRetriever,
    setFolderList,
    setSelectedFiles,
    toggleSelectFolder,
    toggleSelectFile,
    isSelectedFolder,
    isSelectedFile,
    setSelectedBrainstormFiles,
    toggleSelectBrainstormFolder,
    toggleSelectBrainstormFile,
    isSelectedBrainstormFolder,
    isSelectedBrainstormFile,
    setSelectedCreateConceptFiles,
    toggleSelectCreateConceptFolder,
    toggleSelectCreateConceptFile,
    isSelectedCreateConceptFolder,
    isSelectedCreateConceptFile,
    getSettingData,
    openFileManagerModal,
    closeFileManagerModal,
    openConceptManagerModal,
    closeConceptManagerModal,
    openConceptAddFolderModal,
    closeConceptAddFolderModal,
    getConceptFolders,
    getBrainstormList,
    setSocketInterface,
    fetchFolders,
    setSelectedUploadFiles,
    openActivityLogsModal,
    closeActivityLogsModal,
    openShareFeedbackModal,
    closeShareFeedbackModal,
    handleSettingModalOpen,
    handleCloseSettingsModal,
    openStartBrainstormDrawer,
    closeStartBrainstormDrawer,
    openCreateConceptDrawer,
    closeCreateConceptDrawer,
  };

  return (
    <RagContext.Provider value={contextValue}>{children}</RagContext.Provider>
  );
};

export const useRagContext = () => {
  const context = useContext(RagContext);
  if (!context) {
    throw new Error("useUserContext must be used within a RagContextProvider");
  }
  return context;
};
